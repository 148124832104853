import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AuthUtils from '../../utils/auth';
import { AuthorizationActions } from '../../store/authorization';

const SignInWithToken = ({ match, history, signIn }) => {
  if (match.params && match.params.tokenId) {
    AuthUtils.signInWithToken(match.params.tokenId)
      .then((user) => {
        signIn(user);
        AuthUtils.saveCurrentUser(user);
        history.push('/');
      });
  }
  return (
    <div>Waiting...</div>
  );
};

SignInWithToken.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  signIn: PropTypes.func,
};

const mapDispatchToProps = {
  signIn: AuthorizationActions.signIn,
};

export default withRouter(connect(null, mapDispatchToProps)(SignInWithToken));
