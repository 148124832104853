import React, { Component } from 'react';
import Select from 'react-select';
import HttpRequest from '../../utils/httpRequest';
import ConfirmModal from '../../components/ConfirmModal';
import 'react-select/dist/react-select.css';
import './ManageUsers.scss';

const UserRole = {
  NormalUser: 'normalUser',
  Administrator: 'administrator',
};

export const UserRoleOptions = [
  { label: 'NormalUser', value: UserRole.NormalUser },
  { label: 'Administrator', value: UserRole.Administrator },
];

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenConfirmModal: false,
      users: [],
      currentUser: null,
      userRole: UserRole.NormalUser,
    };
    this.addUser = this.addUser.bind(this);
    this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
  }

  componentDidMount() {
    this.updateState = (stateObj) => {
      this.setState(stateObj);
    };
    this.getUsers();
  }

  componentWillUnmount() {
    this.updateState = () => {};
  }

  getUsers() {
    HttpRequest.get('/api/crm/users')
      .then(({ users }) => {
        this.updateState({ users });
      });
  }

  addUser() {
    if (!this.refs.email.value) {
      return;
    }
    HttpRequest.post('/api/crm/users', { email: this.refs.email.value, role: this.state.userRole })
      .then(() => {
        this.getUsers();
      });
  }

  confirmDeleteUser() {
    const { currentUser } = this.state;
    HttpRequest.deleteRequest(`/api/crm/users/${currentUser.id}`)
      .then(() => {
        this.closeConfirmModal();
        this.getUsers();
      });
  }

  closeConfirmModal() {
    this.setState({ isOpenConfirmModal: false });
  }

  deleteUser(currentUser) {
    this.setState({ currentUser, isOpenConfirmModal: true });
  }

  handleUserRoleChange = (item) => {
    this.setState({ userRole: item.value });
  };

  render() {
    const { users, userRole, isOpenConfirmModal } = this.state;
    const administrators = users.filter((user) => user.role === UserRole.Administrator);
    return (
      <div className="manage-users">
        <div className="manage-header">
          <label>User Email</label>
          <input className="form-control" type="email" ref="email" />
          <Select
            className="user-role"
            clearable={false}
            value={userRole}
            onChange={this.handleUserRoleChange}
            options={UserRoleOptions} />
          <button className="btn btn-primary" onClick={this.addUser}>Add</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              users.map((user) => (
                <tr key={user.id}>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    {
                      (user.role === UserRole.NormalUser || (administrators.length > 1 && user.role === UserRole.Administrator)) && (
                        <i className="operation-item far fa-trash-alt" onClick={() => this.deleteUser(user)}></i>
                      )
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <ConfirmModal
          message="Are you sure to delete this user?"
          isOpen={isOpenConfirmModal}
          onConfirm={this.confirmDeleteUser}
          onCancel={this.closeConfirmModal} />
      </div>
    );
  }
}

export default ManageUsers;
