import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import httpRequest from '../../utils/httpRequest';
import './OrganizationDetail.scss';

export default class OrganizationDetail extends Component {
    static propTypes = {
      organizationId: PropTypes.string,
    }

    constructor(props) {
      super(props);
      this.state = {
        organizationInfo: {},
      };
    }

    componentWillMount() {
      this.loading = true;
      const { organizationId } = this.props;
      httpRequest.get(`/api/crm/organizations/${organizationId}`)
        .then(({ organizationInfo }) => {
          if (this.loading) {
            this.loading = false;
            this.setState({ organizationInfo });
          }
        });

      this.loadingServer = true;
      httpRequest.get(`/manage/proxy?organizationID=${organizationId}`)
        .then(({ server }) => {
          if (this.loadingServer) {
            this.loadingServer = false;
            this.setState({ server });
          }
        });
    }

    componentWillUnmount() {
      this.loading = false;
      this.loadingServer = false;
    }

    getRenderUsers() {
      const { organizationInfo } = this.state;
      const users = _.get(organizationInfo, 'Users', []);
      return _.sortBy(users, (u) => !u.isAdmin);
    }

    render() {
      const { server } = this.state;
      const users = this.getRenderUsers();
      return (
            <div className="organization-detail">
                <div className="organization-server">
                    Server: <span>{server}</span>
                </div>
                <div className="organization-users">
                    <span className="item-label">Organization Users</span>
                    <table className={'users-table'}>
                        <thead className="small">
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Numebr</th>
                                <th>Role</th>
                                <th>Status</th>
                                <th>Last Activity Time</th>
                            </tr>
                        </thead>
                        <tbody className="small">
                            {users.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phoneNumber}</td>
                                    <td>{user.isAdmin ? 'Admin' : 'Normal user'}</td>
                                    <td>{user.active ? 'actived' : 'inactived'}</td>
                                    <td>{user.lastActivityTime ? new Date(user.lastActivityTime).toLocaleString() : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
      );
    }
}
