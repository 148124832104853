import React, { Component } from 'react';
import {
  withRouter, Link, NavLink, Route,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StripeProvider } from 'react-stripe-elements';
import AuthUtils from '../utils/auth';
import setInterceptors from '../config/interceptor';
import { AuthorizationActions } from '../store/authorization';
import Organizations from './common/Organizations';
import Orders from './common/Orders';
import ManageProducts from './management/ManageProducts';
import ManageUsers from './management/ManageUsers';
import ManageDiscounts from './management/ManageDiscounts';
import UserProfile from './user/UserProfile';
import UserMenu from './user/UserMenu';
import ManageServer from './management/ManageServer';
import './App.scss';
import 'antd/dist/antd.css';

class App extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    currentUser: PropTypes.object,
    signIn: PropTypes.func,
  };

  constructor(props) {
    super(props);
    setInterceptors(props.history);
  }

  componentDidMount() {
    const currentUser = AuthUtils.getCurrentUser();
    if (currentUser) {
      this.props.signIn(currentUser);
    }
  }

  render() {
    const { isAuthenticated, currentUser, location } = this.props;
    const isShowHeader = location.pathname.indexOf('/receipts') === -1
      && !_.endsWith(location.pathname, '/cashier')
      && !_.endsWith(location.pathname, '/edit');
    return (
      <StripeProvider apiKey="pk_test_Esg270Z77jRJvceKTFHYA1Ix">
        <div className="app-container">
          {
            isShowHeader && (
              <header className="app-header">
                <div className="header-left">
                  {
                    currentUser && <NavLink className="nav-tab" activeClassName="nav-active-tab" to="/orders">Orders</NavLink>
                  }
                  {
                    currentUser && currentUser.role === 'administrator' && (
                      <span>
                        <NavLink className="nav-tab" activeClassName="nav-active-tab" to="/organizations">Organizations</NavLink>
                        <NavLink className="nav-tab" activeClassName="nav-active-tab" to="/manageProducts">Manage Products</NavLink>
                        <NavLink className="nav-tab" activeClassName="nav-active-tab" to="/manageDiscounts">Manage Discounts</NavLink>
                        <NavLink className="nav-tab" activeClassName="nav-active-tab" to="/manageUsers">Manage Users</NavLink>
                        <NavLink className="nav-tab" activeClassName="nav-active-tab" to="/manageServer">Manage Server</NavLink>
                      </span>
                    )
                  }
                </div>
                <div className="header-right">
                  <div className="user-info">
                    {isAuthenticated ? (
                      <UserMenu currentUser={currentUser}/>
                    ) : (
                        <Link className="sign-in" to="/signIn">Sign In</Link>
                    )
                    }
                  </div>
                </div>
              </header>
            )
          }
          <content className="app-content">
            <Route path="/organizations" component={Organizations} />
            <Route path="/orders" component={Orders} />
            <Route path="/profile" component={UserProfile} />
            {
              currentUser && currentUser.role === 'administrator' && (
                <div>
                  <Route path="/manageProducts" component={ManageProducts} />
                  <Route path="/manageUsers" component={ManageUsers} />
                  <Route path="/manageDiscounts" component={ManageDiscounts} />
                  <Route path="/manageServer" component={ManageServer} />
                </div>
              )
            }
          </content>
        </div>
      </StripeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authorization.isAuthenticated,
  currentUser: state.authorization.currentUser,
});

const mapDispatchToProps = {
  signIn: AuthorizationActions.signIn,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
