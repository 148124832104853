import React, { Component } from 'react';
import _ from 'lodash';
import {
  Button, Col, Divider, Input, Modal, Popconfirm, Row, Table,
} from 'antd';
import './ManageServer.scss';
import httpRequest from '../../utils/httpRequest';

export default class ManageServer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servers: [],
      idleServer: '',
      fallbackServer: '',
      isModalVisible: false,
      addingServer: '',
      serverOrganizationCountMap: {},
    };
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    const allRequests = [];
    allRequests.push(httpRequest.get('/manage/allServers'));
    allRequests.push(httpRequest.get('/manage/idleServer'));
    allRequests.push(httpRequest.get('/manage/fallbackServer'));
    allRequests.push(httpRequest.get('/manage/allProxy'));
    Promise.all(allRequests).then(([allServersResult, idleServerResult, fallbackServerResult, allProxyResult]) => {
      const servers = allServersResult.allServers;
      const { idleServer } = idleServerResult;
      const { fallbackServer } = fallbackServerResult;
      const { allProxy } = allProxyResult;
      const serverOrganizationCountMap = {};
      _.forEach(allProxy, (server) => {
        if (!serverOrganizationCountMap[server]) {
          serverOrganizationCountMap[server] = 1;
        } else {
          serverOrganizationCountMap[server]++;
        }
      });
      this.setState({
        servers, idleServer, fallbackServer, serverOrganizationCountMap,
      });
    }).catch((err) => console.log(err));
  }

  setAsIdle({ server }) {
    httpRequest.post(`manage/idleServer?server=${server}`).then(() => {
      this.setState({ idleServer: server });
    });
  }

  setAsFallback({ server }) {
    httpRequest.post(`manage/fallbackServer?server=${server}`).then(() => {
      this.setState({ fallbackServer: server });
    });
  }

  onInputChange({ target: { value } }) {
    this.setState({ addingServer: value });
  }

  closeModal() {
    this.setState({ isModalVisible: false, addingServer: '' });
  }

  handleOk() {
    const { servers, addingServer } = this.state;
    httpRequest.post(`/manage/addServer?server=${addingServer}`).then(() => {
      this.setState({ servers: [...servers, addingServer] });
    }).catch((err) => console.log(err));
    this.closeModal();
  }

  handleCancel() {
    this.closeModal();
  }

  render() {
    const {
      servers, fallbackServer, idleServer, isModalVisible, addingServer, serverOrganizationCountMap,
    } = this.state;
    const columns = [
      {
        title: '编号',
        dataIndex: 'index',
        key: 'index',
      },
      {
        title: '地址',
        dataIndex: 'server',
        key: 'server',
      },
      {
        title: '服务组织数量',
        dataIndex: 'organizationCount',
        key: 'organizationCount',
        render: (text, record) => (
                    <span>
                        {serverOrganizationCountMap[record.server] || 0}
                    </span>
        ),
      },
      {
        title: '操作',
        dataIndex: 'operation',
        render: (text, record) => (
                    <span>
                      <Popconfirm title="确定吗?" onConfirm={() => this.setAsIdle(record)}>
                        <a style={{ marginRight: 48 }}>Set as Idle</a>
                      </Popconfirm>
                      <Popconfirm title="确定吗?" onConfirm={() => this.setAsFallback(record)}>
                        <a>Set as Fallback</a>
                      </Popconfirm>

                    </span>
        ),
      },
    ];
    const data = servers.map((s, i) => ({ key: s, index: i + 1, server: s }));
    return (
            <div className="manage-server-container">
                <Row>
                    <Col className="gutter-row" span={2}>
                        IdleServer
                    </Col>
                    <Col className="gutter-row" span={8}>
                        {idleServer}
                    </Col>
                </Row>
                <Row>
                    <Col className="gutter-row" span={2}>
                        FallbackServer
                    </Col>
                    <Col className="gutter-row" span={8}>
                        {fallbackServer}
                    </Col>
                </Row>
                <Divider/>
                <div className="server-table-container">
                    <Button type="primary" onClick={() => this.setState({ isModalVisible: true })}>
                        添加一个服务器
                    </Button>
                    <Table columns={columns} dataSource={data} pagination={false}/>
                </div>
                <Modal title="添加服务器" visible={isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
                    <Input value={addingServer} onChange={this.onInputChange}/>
                </Modal>
            </div>
    );
  }
}
