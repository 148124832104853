import ActionTypes from '../constants/action-types';

const initialState = {
  currentUser: null,
  isAuthenticated: false,
};

export const AuthorizationActions = {
  signIn: (user) => ({ type: ActionTypes.SIGN_IN, user }),
  signOut: () => ({ type: ActionTypes.SIGN_OUT }),
};

export const authorization = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.user,
      };
    case ActionTypes.SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        currentUser: null,
      };
    default:
      return state;
  }
};
