import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './CustomDropdown.scss';

export const DropdownDirection = {
  // alignment + dropDirection
  LeftDown: 0,
  LeftUp: 1,
  RightDown: 2,
  RightUp: 3,
  UpRight: 4,
  UpLeft: 5,
  DownRight: 6,
  DownLeft: 7,
};

class CustomDropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    dropdownTriggerRender: PropTypes.func,
    isOpenedAfterClickList: PropTypes.bool,
    dropDirection: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.dropdownTriggerRef = this.dropdownTriggerRef.bind(this);
    this.dropdownListRef = this.dropdownListRef.bind(this);
    this.handleClickTrigger = this.handleClickTrigger.bind(this);
    this.handleClickList = this.handleClickList.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentClick, true);
    document.addEventListener('touchend', this.handleDocumentClick, true);
    document.addEventListener('scroll', this.handleDocumentClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentClick, true);
    document.removeEventListener('touchend', this.handleDocumentClick, true);
    document.removeEventListener('scroll', this.handleDocumentClick, true);
  }

  componentDidUpdate() {
    this.adjustDropdownListPosition();
  }

  handleDocumentClick(event) {
    if (this.state.isOpen && !this.dropdownList.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  handleClickList(event) {
    event.nativeEvent.stopImmediatePropagation();
    if (!this.props.isOpenedAfterClickList && this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  handleClickTrigger(event) {
    event.stopPropagation();
    event.preventDefault();
    if (!this.props.disabled) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  adjustDropdownListPosition() {
    if (!this.state.isOpen) {
      if (!this.dropdownList) {
        return;
      }
      this.dropdownList.style.opacity = 0;
      this.dropdownList.style.pointerEvents = 'none';
    } else {
      setTimeout(() => {
        if (!this.dropdownTrigger || !this.dropdownList) {
          return;
        }
        const { top, left, minWidth } = this.getDropdownListPosition();
        this.dropdownList.style.top = `${top}px`;
        this.dropdownList.style.left = `${left}px`;
        this.dropdownList.style.minWidth = `${minWidth}px`;
        this.dropdownList.style.opacity = 1;
        this.dropdownList.style.pointerEvents = 'auto';
      }, 100);
    }
  }

  getDropdownListPosition() {
    const dropdownTriggerRect = this.dropdownTrigger.getBoundingClientRect();
    const dropdownListRect = this.dropdownList.firstElementChild.getBoundingClientRect();
    const { innerWidth: winInnerWidth, innerHeight: winInnerHeight } = window;
    const {
      LeftDown, RightDown, LeftUp, RightUp, UpRight, UpLeft, DownLeft, DownRight,
    } = DropdownDirection;
    const dropDirection = this.props.dropDirection || LeftDown;
    let dropdownListPosition;
    if (dropDirection === LeftDown) {
      dropdownListPosition = {
        top: dropdownTriggerRect.height,
        left: 0,
      };
    } else if (dropDirection === LeftUp) {
      dropdownListPosition = {
        top: -dropdownListRect.height,
        left: 0,
      };
    } else if (dropDirection === RightDown) {
      dropdownListPosition = {
        top: dropdownTriggerRect.height,
        left: dropdownTriggerRect.width - dropdownListRect.width,
      };
    } else if (dropDirection === RightUp) {
      dropdownListPosition = {
        top: -dropdownListRect.height,
        left: dropdownTriggerRect.width - dropdownListRect.width,
      };
    } else if (dropDirection === UpRight) {
      dropdownListPosition = {
        top: 0,
        left: dropdownTriggerRect.width,
      };
    } else if (dropDirection === UpLeft) {
      dropdownListPosition = {
        top: 0,
        left: -dropdownListRect.width,
      };
    } else if (dropDirection === DownRight) {
      dropdownListPosition = {
        top: dropdownTriggerRect.height - dropdownListRect.height,
        left: dropdownTriggerRect.width,
      };
    } else if (dropDirection === DownLeft) {
      dropdownListPosition = {
        top: dropdownTriggerRect.height - dropdownListRect.height,
        left: -dropdownListRect.width,
      };
    }

    // adjust position for drop direction
    if (dropDirection === LeftDown || dropDirection === RightDown) {
      if ((dropdownTriggerRect.bottom + dropdownListRect.height > winInnerHeight)
        && (dropdownTriggerRect.top - dropdownListRect.height > 0)) {
        dropdownListPosition.top = -dropdownListRect.height;
      }
    } else if (dropDirection === LeftUp || dropDirection === RightUp) {
      if ((dropdownTriggerRect.top - dropdownListRect.height < 0)
        && (dropdownTriggerRect.bottom + dropdownListRect.height < winInnerHeight)) {
        dropdownListPosition.top = dropdownTriggerRect.height;
      }
    } else if (dropDirection === UpRight || dropDirection === DownRight) {
      if ((dropdownTriggerRect.right + dropdownListRect.width > winInnerWidth)
        && (dropdownTriggerRect.left - dropdownListRect.width > 0)) {
        dropdownListPosition.left = -dropdownListRect.width;
      }
    } else if (dropDirection === UpLeft || dropDirection === DownLeft) {
      if ((dropdownTriggerRect.left - dropdownListRect.width < 0)
        && (dropdownTriggerRect.right + dropdownListRect.width < winInnerWidth)) {
        dropdownListPosition.left = dropdownTriggerRect.width;
      }
    }

    // adjust for alignment
    if (dropDirection === LeftDown || dropDirection === LeftUp) {
      if ((dropdownTriggerRect.left + dropdownListRect.width > winInnerWidth)
        && (dropdownTriggerRect.right - dropdownListRect.width > 0)) {
        dropdownListPosition.left = dropdownTriggerRect.width - dropdownListRect.width;
      }
    } else if (dropDirection === RightDown || dropDirection === RightUp) {
      if ((dropdownTriggerRect.right - dropdownListRect.width < 0)
        && (dropdownTriggerRect.left + dropdownListRect.width < winInnerWidth)) {
        dropdownListPosition.left = 0;
      }
    } else if (dropDirection === UpRight || dropDirection === UpLeft) {
      if ((dropdownTriggerRect.top + dropdownListRect.height > winInnerHeight)
        && (dropdownTriggerRect.bottom - dropdownListRect.height > 0)) {
        dropdownListPosition.top = dropdownTriggerRect.height - dropdownListRect.height;
      }
    } else if (dropDirection === DownRight || dropDirection === DownLeft) {
      if ((dropdownTriggerRect.bottom - dropdownListRect.height < 0)
        && (dropdownTriggerRect.top + dropdownListRect.height < winInnerHeight)) {
        dropdownListPosition.top = 0;
      }
    }

    return dropdownListPosition;
  }

  dropdownTriggerRef(dropdownTrigger) {
    this.dropdownTrigger = dropdownTrigger;
  }

  dropdownListRef(dropdownList) {
    this.dropdownList = dropdownList;
  }

  render() {
    const { children, dropdownTriggerRender, className } = this.props;
    const { isOpen } = this.state;
    const dropdownClassName = `custom-dropdown-wrapper ${className || ''}`;
    return <div className={dropdownClassName}>
      <div className="custom-dropdown-trigger" ref={this.dropdownTriggerRef}
           onClick={this.handleClickTrigger}
           onTouchEnd={this.handleClickTrigger}>{dropdownTriggerRender()}</div>
      {isOpen
        && <div className="custom-dropdown-list" ref={this.dropdownListRef}
          onClick={this.handleClickList}
          onTouchEnd={this.handleClickList}>{children}
        </div>}
    </div>;
  }
}
export default CustomDropdown;
