import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

class ConfirmModal extends Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.ok = this.ok.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  cancel() {
    this.props.onCancel();
  }

  ok() {
    this.props.onConfirm();
  }

  render() {
    const { message, isOpen } = this.props;
    return (
      <Modal
        className="modal-container"
        isOpen={isOpen}
        contentLabel="Confirm"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false} >
        <div className="confirm-modal-content">
        <div className="modal-header">
          <span>Confirm</span>
        </div>
        <div className="modal-content">
          { message }
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={this.ok}>OK</button>
          <button className="btn btn-primary" onClick={this.cancel}>Cancel</button>
        </div>
        </div>
      </Modal>
    );
  }
}

export default ConfirmModal;
