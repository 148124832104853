import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import QueryString from 'query-string';
import Modal from 'react-modal';
import _ from 'lodash';
import moment from 'moment';
import './Organizations.scss';
import httpRequest from '../../utils/httpRequest';
import OrganizationDetail from './OrganizationDetail';

const getExpiredDate = (organization) => {
  const editionUsage = _.get(organization, 'teamEdition') || _.get(organization, 'trialEdition');
  if (!editionUsage) {
    return null;
  }
  return new Date(new Date(editionUsage.startTime).valueOf() + editionUsage.duration);
};

const getReadableFileSize = (sizeInBytes) => {
  if (!sizeInBytes) return '0B';
  const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  return `${(sizeInBytes / (1024 ** i)).toFixed(2) * 1}${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

class Organizations extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      showNewOrganizationsDialog: false,
      showUpdateUsageDialog: false,
    };
    this.showOrganizationDetail = this.showOrganizationDetail.bind(this);
    this.closeOrganizationDetail = this.closeOrganizationDetail.bind(this);
    this.upgradeToEnterprisePlan = this.upgradeToEnterprisePlan.bind(this);
    this.createOrganizations = this.createOrganizations.bind(this);
    this.updateOrganizationUsage = this.updateOrganizationUsage.bind(this);

    this.organizationIdsInput = React.createRef();
    this.suiteCodeInput = React.createRef();
    this.expiredDateInput = React.createRef();
  }

  componentWillMount() {
    this.loadOrganizations();
  }

  componentWillUnmount() {
    this.loading = false;
  }

  loadOrganizations() {
    this.loading = true;
    httpRequest.get('/api/crm/organizations')
      .then(({ organizations }) => {
        if (this.loading) {
          this.loading = false;
          this.setState({ organizations });
        }
      });
  }

  showOrganizationDetail(organizationID) {
    const { history, location } = this.props;
    history.push(`${location.pathname}?id=${organizationID}`);
  }

  closeOrganizationDetail() {
    const { history, location } = this.props;
    history.push(location.pathname);
  }

  upgradeToEnterprisePlan(activeOrganization) {
    httpRequest.post('/api/crm/usages', null, {
      params: {
        suiteCode: 'enterprise-20users-1y',
        organizationId: activeOrganization.id,
      },
    });
    this.loadOrganizations();
  }

  createOrganizations() {
    if (!this.organizationIdsInput.current.value || !this.suiteCodeInput.current.value) {
      return;
    }
    const organzations = this.organizationIdsInput.current.value.replace(' ', '').split(',');
    const promises = _.map(organzations, (orgId) => {
      httpRequest.post('/api/crm/usages', null, {
        params: { suiteCode: this.suiteCodeInput.current.value, organizationId: orgId },
      });
    });
    promises.push(new Promise(() => {
      this.loadOrganizations();
    }));
    httpRequest.serialPromises(promises);
  }

  updateOrganizationUsage() {
    const { organizations } = this.state;
    const { location } = this.props;
    const query = QueryString.parse(location.search);
    const activeOrganization = organizations.find((o) => o.id === (query && query.id));

    const editionUsage = activeOrganization.teamEdition || activeOrganization.trialEdition;
    if (!editionUsage) {
      alert('Create Edition First!');
      return;
    }

    const expiredDate = this.expiredDateInput.current.value;
    const duration = new Date(expiredDate) - new Date(editionUsage.startTime);

    httpRequest.post(`/api/crm/usages/updateDuration/${editionUsage.id}`, { duration }).then(() => {
      alert('Update Completed!');
      this.loadOrganizations();
      this.setState({
        showUpdateUsageDialog: false,
        updatingExpiredDate: null,
      });
    });
  }

  render() {
    const {
      organizations, showNewOrganizationsDialog, showUpdateUsageDialog, updatingExpiredDate,
    } = this.state;
    const { location } = this.props;
    const query = QueryString.parse(location.search);
    const activeOrganization = organizations.find((o) => o.id === (query && query.id));
    const activeOrganizationExpiredDate = activeOrganization && getExpiredDate(activeOrganization);

    return (
      <div className="organizations">
        <p>
          <button className="btn btn-primary" onClick={() => { this.setState({ showNewOrganizationsDialog: true }); }}>Create New Organizations Usages</button>
        </p>
        <div className="organizations-content">
          <table>
            <thead className="small">
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th>User Usage</th>
                <th>Space Usage</th>
                <th>Email Usage</th>
                <th>Expired Date</th>
                <th>Created Time</th>
              </tr>
            </thead>
            <tbody className="small">
              {_.sortBy(organizations, (org) => org.createdTime && new Date(org.createdTime)).map((organization) => (
                <tr key={organization.id}>
                  <td className="organization-name" onClick={() => this.showOrganizationDetail(organization.id)}>{organization.name}</td>
                  <td>{organization.id}</td>
                  <td>
                    <span>{_.get(organization, 'userUsage.used', 0)}</span>/<span>{_.get(organization, 'userUsage.total', 0)}</span>
                  </td>
                  <td>
                    <span>{getReadableFileSize(_.get(organization, 'spaceUsage.used', 0) * (1024 ** 3))}</span>/<span>{getReadableFileSize(_.get(organization, 'spaceUsage.total', 0) * (1024 ** 3))}</span>
                  </td>
                  <td>
                    <span>{_.get(organization, 'emailUsage.used', 0)}</span>/<span>{_.get(organization, 'emailUsage.total', 0)}</span>
                  </td>
                  <td>
                    {(function () {
                      const expiratedDate = getExpiredDate(organization);
                      return expiratedDate ? expiratedDate.toLocaleDateString() : 'Unlimited';
                    }())}
                  </td>
                  <td>
                    {organization.createdTime && new Date(organization.createdTime).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {activeOrganization && (
          <Modal
            className="modal-container organization-detail-modal"
            isOpen={true}
            contentLabel="card"
            ariaHideApp={false}
            onRequestClose={this.closeOrganizationDetail}>
            <div className="organization-thumbnail">
              <h3 className="header">
                <span>Organization Detail</span>
                <button className="btn btn-primary" onClick={() => { this.setState({ showUpdateUsageDialog: true }); }}>Update Expired Date</button>
              </h3>
              <div className="organization-usage-table">
                <table>
                  <thead className="small">
                    <tr>
                      <th>Name</th>
                      <th>ID</th>
                      <th>User Usage</th>
                      <th>Space Usage</th>
                      <th>Email Usage</th>
                      <th>Expired Date</th>
                      <th>Created Time</th>
                    </tr>
                  </thead>
                  <tbody className="small">
                    <tr key={activeOrganization.id}>
                      <td>{activeOrganization.name}</td>
                      <td>{activeOrganization.id}</td>
                      <td>
                        <span>{_.get(activeOrganization, 'userUsage.used', 0)}</span>/<span>{_.get(activeOrganization, 'userUsage.total', 0)}</span>
                      </td>
                      <td>
                        <span>{getReadableFileSize(_.get(activeOrganization, 'spaceUsage.used', 0) * (1024 ** 3))}</span>/<span>{getReadableFileSize(_.get(activeOrganization, 'spaceUsage.total', 0) * (1024 ** 3))}</span>
                      </td>
                      <td>
                        <span>{_.get(activeOrganization, 'emailUsage.used', 0)}</span>/<span>{_.get(activeOrganization, 'emailUsage.total', 0)}</span>
                      </td>
                      <td>
                        {activeOrganizationExpiredDate ? activeOrganizationExpiredDate.toLocaleDateString() : 'Unlimited'}
                      </td>
                      <td>
                        {activeOrganization.createdTime && new Date(activeOrganization.createdTime).toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {!activeOrganization.teamEdition && !activeOrganization.userUsage && !activeOrganization.spaceUsage && !activeOrganization.emailUsage
                  && <button className="btn btn-primary" onClick={() => this.upgradeToEnterprisePlan(activeOrganization)}>Upgrade to Enterprise version</button>
                }
              </div>
            </div>
            <div className='modal-content'>
              <OrganizationDetail organizationId={query.id} />
            </div>
          </Modal>
        )}

        <Modal
          className="modal-container"
          isOpen={showNewOrganizationsDialog}
          contentLabel="card"
          ariaHideApp={false}
          onRequestClose={() => { this.setState({ showNewOrganizationsDialog: false }); }}>
          <div className='modal-content'>
            <label>OrganizationIds (split by comma)</label>
            <textarea className="form-control" type="text" ref={this.organizationIdsInput} />
            <label>Suite Code</label>
            <input className="form-control" type="text" ref={this.suiteCodeInput} />
            <button className="btn btn-primary" onClick={this.createOrganizations}>Create Organizations Usages</button>
          </div>
        </Modal>
        {showUpdateUsageDialog
        && <Modal
          className="modal-container"
          parentSelector={() => document.querySelector('.modal-container.organization-detail-modal')}
          isOpen={true}
          contentLabel="card"
          ariaHideApp={false}
          onRequestClose={() => {
            this.setState({
              showUpdateUsageDialog: false,
              updatingExpiredDate: null,
            });
          }}>
          <div className='modal-content'>
            <label>New Expired Date</label>
            <input ref={this.expiredDateInput}
              className="form-control"
              type="datetime-local"
              value={(function () {
                const dt = updatingExpiredDate || activeOrganizationExpiredDate;
                return dt && moment(dt).format('YYYY-MM-DDTHH:mm');
              }())}
              onChange={(evt) => {
                this.setState({
                  updatingExpiredDate: new Date(evt.target.value),
                });
              }}
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" required
            />
            <button className="btn btn-primary" onClick={this.updateOrganizationUsage}>Update</button>
          </div>
        </Modal>
        }
      </div>
    );
  }
}

export default withRouter(Organizations);
