import axios from 'axios';
import _ from 'lodash';

const setInterceptors = (history) => {
  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => config, // Do something before request is sent
    (error) => Promise.reject(error), // Do something with request error
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => response, // Do something with response data
    (error) => {
      if (_.result(error, 'response.status') === 401) {
        history.replace('/signIn');
      }
      return Promise.reject(error);
    },
  );
};

export default setInterceptors;
